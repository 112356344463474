import cn from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { checkPassword } from '../../../../utils/form.util';

import { Button } from '../../../button/button.component';
import { Input } from '../../../input/input.component';
import { ModalActions } from '../modal-actions/modal-actions.component';
import { ModalContent } from '../modal-content/modal-content.component';
import { ModalHeader } from '../modal-header/modal-header.component';
import { StyledModalChangePassword } from './modal-change-password.styled';

export interface ModalChangePasswordProps {
  className?: string;
}

export const ModalChangePassword = ({ className }: ModalChangePasswordProps) => {
  const { t } = useTranslation()
  const [oldPassword, setOldPassword] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    const isNewPasswordChecked = checkPassword(password);
    setDisabled(isNewPasswordChecked && password === confirmPassword);
  }, [confirmPassword, password]);

  const handleChangePassword = useCallback(() => {

  }, []);

  return (
    <StyledModalChangePassword className={cn('modal-add-workspace', className)}>
      <ModalHeader close={true}>
        {t('change-password')}
      </ModalHeader>
      <ModalContent>
        <Input
          className="field"
          label={t('profile:old-password')}
          name="old-password"
          type="password"
          value={oldPassword}
          onChange={setOldPassword}
        />
        <Input
          className="field"
          label={t('profile:new-password')}
          name="password"
          type="password"
          value={password}
          onChange={setPassword}
        />
        <Input
          className="field"
          label={t('profile:confirm-new-password')}
          name="confirm-password"
          type="password"
          value={confirmPassword}
          onChange={setConfirmPassword}
        />
      </ModalContent>
      <ModalActions>
        <Button
          className="change-password"
          onClick={handleChangePassword}
          disabled={!disabled}
        >
          {t('profile:change')}
        </Button>
      </ModalActions>
    </StyledModalChangePassword>
  );
}