import cn from 'classnames';
import { MouseEventHandler } from 'react';

import { LoaderAnimation } from '../loader/loader.component';
import { StyledButton, StyledRaisedButton } from './button.styled';

export interface ButtonProps {
  className?: string;
  onClick: MouseEventHandler;
  disabled?: boolean;
  children: JSX.Element | string;
  loading?: boolean;
  inverted?: boolean;
}

export const Button = ({ children, onClick, className, loading, disabled, inverted }: ButtonProps) => {
  return (
    <StyledButton className={cn(className, { disabled, inverted })} onClick={onClick} disabled={disabled}>
      {loading ? <LoaderAnimation light /> : children}
    </StyledButton>
  )
}

export const RaisedButton = ({ children, onClick, className, disabled }: ButtonProps) => {
  return (
    <StyledRaisedButton className={cn(className, { disabled })} onClick={onClick} disabled={disabled}>
      { children}
    </StyledRaisedButton>
  );
}