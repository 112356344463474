import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { ReactComponent as Logo } from '../../assets/images/mockingbird-logo.svg';
import { Routes } from '../../router/routes';
import { useAuth } from '../../store/auth.context';
import { formatName } from '../../utils/format.util';
import { UserDropdown } from './components/user-dropdown/user-dropdown.component';
import { StyledPageLayout } from './page-layout.styled';

export interface PageLayoutProps {
  children: JSX.Element;
}

export const PageLayout = ({ children }: PageLayoutProps) => {

  const { t } = useTranslation();
  const history = useHistory();
  const auth = useAuth();

  const handleClickLogo = () => {
    history.push(Routes.workspaces);
  }

  return (
    <StyledPageLayout>
      <div className="header">
        <div className="app-logo">
          <Logo onClick={handleClickLogo} />
        </div>
        <div className="user">
          <div
            className="user-name"
            dangerouslySetInnerHTML={{ __html: t('profile:welcome', { name: formatName(auth.user) }) }}
          />
          <div className="user-icon">
            <UserDropdown />
          </div>
        </div>
      </div>
      <div className="page-content">
        {children}
      </div>
    </StyledPageLayout>
  );
}