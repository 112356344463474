import styled from 'styled-components';

import { appTheme } from '../../styles/theme.styled';

export const StyledWorkspaceDetails = styled.div`

  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: row;

  .routes-list {
    display: flex;
    flex-direction: column;

    width: 300px;
    margin: 1rem;

    border-radius: 4px;
    background-color: ${appTheme((theme) => theme.colors.primary)}66;
    box-shadow: ${appTheme((theme) => theme.shadows.primary)};
    overflow-y: auto;
    
    .actions {
      padding: 1rem;

      .add-action {
        width: 100%;
      }
    }

    .routes-list-container {
      flex-grow: 1;
      height: 0;
      overflow-y: auto;
      padding: 1rem;

      .route-list-card {
        margin-bottom: 1rem;
      }

      .routes-empty-state {
        /* color: ${appTheme((theme) => theme.colors.primary)}; */
      }
    }

  }

  .route-details {
    flex-grow: 1;
    padding: 1rem;
    height: 100%;
  }
`;