import Avatar from 'boring-avatars';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { getTokens } from '../../api/token.api';
import { editProfile } from '../../api/user.api';

import { Button } from '../../components/button/button.component';
import { Input } from '../../components/input/input.component';
import { ModalName } from '../../components/modal/modal.registry';
import { TokensTable } from '../../components/tokens-table/tokens-table.component';
import { Token } from '../../models/token.model';
import { IEditProfile } from '../../models/user.model';
import { useAuth } from '../../store/auth.context';
import { useModal } from '../../store/modal.context';
import { mainTheme } from '../../styles/theme.styled';
import { callApi } from '../../utils/make-api.util';
import { StyledProfile } from './profile.styled';

export const Profile = () => {

  const { t } = useTranslation()
  const history = useHistory()
  const auth = useAuth();
  const modal = useModal();

  const [tokens, setTokens] = useState<Token[]>([]);

  const [firstName, setFirstName] = useState(auth.user.firstName);
  const [lastName, setLastName] = useState(auth.user.lastName);

  const [updateError, setUpdateError] = useState(false);

  useEffect(() => {
    callApi(
      getTokens(),
      (tokens) => setTokens(tokens),
    )
  }, []);

  const onChangePassword = () => {
    modal.show(ModalName.CHANGE_PASSWORD);
  }
  const onSave = () => {
    const updateUser: IEditProfile = {
      id: auth.user.id,
      firstName,
      lastName,
    }
    callApi(
      editProfile(updateUser),
      (user) => {
        setUpdateError(false);
        auth.updateUser(user);
      },
      () => setUpdateError(true),
    )
  }

  const onGoBack = () => {
    history.goBack();
  }

  const onAddToken = useCallback(async () => {
    await modal.show(ModalName.ADD_TOKEN);
    callApi(
      getTokens(),
      (tokens) => setTokens(tokens),
    )
  }, [modal]);

  const onRefreshTokens = useCallback(() => {
    callApi(
      getTokens(),
      (tokens) => setTokens(tokens),
    )
  }, []);

  return (
    <StyledProfile>

      <div className="user-avatar">
        <Avatar
          size="9rem"
          name={String(auth.user.id)}
          variant="beam"
          colors={mainTheme.avatar}
        />
      </div>
      <div className="user-info">

        <h2 className="title">{t('profile:personal-informations')}</h2>

        <div className="user-name">
          <Input
            name="first-name"
            value={firstName}
            placeholder={t('profile:name')}
            onChange={setFirstName}
          />
          <Input
            name="last-name"
            value={lastName}
            placeholder={t('profile:last-name')}
            onChange={setLastName}
          />
        </div>

        <div className="user-password">
          <div className="label">
            {t('profile:change-password')}:
          </div>
          <Button onClick={onChangePassword} inverted>{t('profile:change')}</Button>
        </div>
      </div>

      <div className="tokens-container">
        <TokensTable className="tokens-table" tokens={tokens} onRefresh={onRefreshTokens} />
        <div className="token-actions">
          <Button onClick={onAddToken}>{t('profile:token:add')}</Button>
        </div>
      </div>

      <div className="actions">
        <Button className="go-back" inverted onClick={onGoBack}>{t('profile:go-back')}</Button>
        <Button className="save" onClick={onSave}>{t('profile:save')}</Button>
      </div>
      <div className="errors">
        {updateError && <div className="update-error">{t('profile:errors:update-profile')}</div>}
      </div>

    </StyledProfile>
  )
}