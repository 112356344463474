import cn from 'classnames';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useModal } from '../../../../store/modal.context';
import { Button } from '../../../button/button.component';
import { ModalActions } from '../modal-actions/modal-actions.component';
import { ModalContent } from '../modal-content/modal-content.component';
import { ModalHeader } from '../modal-header/modal-header.component';
import { StyledModalDialog } from './modal-dialog.styled';

export interface ModalChangePasswordProps {
  className?: string;
}

export interface DialogData {
  title: string;
  message: string;
}
export enum DialogResult {
  CANCEL = 'CANCEL',
  CONFIRM = 'CONFIRM',
}

export const ModalDialog = ({ className }: ModalChangePasswordProps) => {
  const { hide, data } = useModal();
  const { t } = useTranslation();

  const onCancelHandler = useCallback(() => {
    hide(DialogResult.CANCEL);
  }, [hide]);

  const onConfirmHandler = useCallback(() => {
    hide(DialogResult.CONFIRM);
  }, [hide]);

  return (
    <StyledModalDialog className={cn('modal-add-token', className)}>
      <ModalHeader close={true} onClose={onCancelHandler}>
        {data.title}
      </ModalHeader>
      <ModalContent>
        {data.message}
      </ModalContent>
      <ModalActions>
        <Button
          className="cancel-action"
          inverted
          onClick={onCancelHandler}
        >
          {t('dialog:cancel')}
        </Button>
        <Button
          className="confirm-action"
          onClick={onConfirmHandler}
        >
          {t('dialog:confirm')}
        </Button>
      </ModalActions>
    </StyledModalDialog>
  );
}