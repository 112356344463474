import { ModalAddRoute } from './components/modal-add-route/modal-add-route.component';
import { ModalAddToken } from './components/modal-add-token/modal-add-token.component';
import { ModalAddWorkspace } from './components/modal-add-workspace/modal-add-workspace.component';
import { ModalChangePassword } from './components/modal-change-password/modal-change-password.component';
import { ModalDialog } from './components/modal-dialog/modal-dialog.component';

export enum ModalName {
  ADD_WORKSPACE = 'ADD_WORKSPACE',
  ADD_ROUTE = 'ADD_ROUTE',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  ADD_TOKEN = 'ADD_TOKEN',
  DIALOG = 'DIALOG',
}

export interface IModalRegistry {
  [key: string]: (...props: any[]) => JSX.Element;
}

export const ModalRegistry: IModalRegistry = {
  [ModalName.ADD_WORKSPACE]: ModalAddWorkspace,
  [ModalName.ADD_ROUTE]: ModalAddRoute,
  [ModalName.CHANGE_PASSWORD]: ModalChangePassword,
  [ModalName.ADD_TOKEN]: ModalAddToken,
  [ModalName.DIALOG]: ModalDialog,
}