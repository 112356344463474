import styled from "styled-components";
import { appTheme } from "../../styles/theme.styled";

export const StyledCopyLabel = styled.div`

  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding: .5rem 0;
  border-radius: 4px;
  width: fit-content;
  transition: background-color .3s ease, color .3s ease, box-shadow .3s ease, padding .3s ease;
  cursor: pointer;
  
  &:hover {
    padding: .5rem;
    background-color: ${appTheme((theme) => theme.colors.primary)};
    color: ${appTheme((theme) => theme.colors.primaryAccent)};
    box-shadow: ${appTheme(theme => theme.shadows.small)};


    .copy-icon {
      fill: ${appTheme(theme => theme.colors.primaryAccent)};
    }
  }

  .copy-label-label {
    margin-right: 0.5rem;
  }

  .copy-icon {
    cursor: pointer;
    transition: fill .3s ease;
    height: 1rem;
    align-self: flex-start;

    .icon {
      height: 100%;
    }
  }

    
`;
