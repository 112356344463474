import cn from 'classnames';
import { useToast } from '../../store/toast.context';
import { ReactComponent as CancelIcon } from '../../assets/icons/cancel.svg';

import { StyledToast } from './toast.styled';

export interface ToastProps {
  className?: string;
}

export const Toast = ({ className }: ToastProps) => {

  const { message, isShown, close } = useToast();

  return (
    <StyledToast className={cn('toast', className, { visible: isShown })} onClick={close}>
      <div className="message">
        {message}
      </div>
      <CancelIcon className="close-icon" />
    </StyledToast>
  );
}