import cn from 'classnames';

import { StyledEmptyState } from './empty-state.styled';

export interface EmptyStateProps {
  children: JSX.Element | JSX.Element[] | string;
  className?: string;
}

export const EmptyState = ({ className, children }: EmptyStateProps) => {
  return (
    <StyledEmptyState className={cn('empty-state', className)}>
      {children}
    </StyledEmptyState>
  );
}