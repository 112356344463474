import { StyledDropdown } from "./dropdown.styled";
import cn from 'classnames';
import { useCallback } from "react";

export enum DropdownDirection {
  left,
  right,
}

export interface DropdownProps {
  open: boolean;
  onOpen: (open: boolean) => void;
  direction?: DropdownDirection;
  handler: JSX.Element;
  children: JSX.Element;
}

export const Dropdown = ({
  open,
  onOpen,
  direction = DropdownDirection.right,
  handler,
  children,
}: DropdownProps) => {
  const handleClick = useCallback(() => {
    onOpen?.(!open);
  }, [onOpen, open])

  return (
    <StyledDropdown>
      <div
        className="dropdown-handler"
        onClick={handleClick}
      >
        {handler}
      </div>
      <div
        className={cn("dropdown-content", {
          open,
          right: direction === DropdownDirection.right,
          left: direction === DropdownDirection.left,
        })}
      >
        {children}
      </div>

    </StyledDropdown>
  )
}