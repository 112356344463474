import { useLocation } from 'react-router';

export const callApi = async (
  handler: Promise<any>,
  onSuccess?: (data: any) => Promise<any> | void,
  onError?: (error: Error) => Promise<any> | void,
): Promise<any> => {
  try {
    return onSuccess?.(await handler);
  } catch (error) {
    return onError?.(error);
  }
}

export const useQuery = () => {
  return Object.fromEntries(new URLSearchParams(useLocation().search));
}
