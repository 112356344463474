import { IRoute, IUpdateRoute } from "../models/route.model";
import { authHttp } from "./http"

export const getRoute = (routeId: string) => {
  return authHttp.get(`/routes/${routeId}`);
}

export const getRoutePayload = (routeId: string) => {
  return authHttp.get(`/routes/${routeId}/payload`);
}

export const updateRoute = (routeId: string, payload: IUpdateRoute): Promise<IRoute> => {
  return authHttp.post(`/routes/${routeId}`, payload);
}

export const updateRoutePayload = (routeId: string, payload: any): Promise<any> => {
  return authHttp.post(`/routes/${routeId}/payload`, { payload })
}

export const createRoute = (name: string, description: string, mock: string, workspaceId: string): Promise<IRoute> => {
  return authHttp.post('/routes', { name, description, mock, workspace: workspaceId });
}