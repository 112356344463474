export const Routes = {

  // pre-login
  login: '/login',
  signup: '/signup',
  forgotPassword: '/forgot-password',
  checkEmail: '/check-email',
  changePassword: '/change-password',
  emailVerification: '/email-verification',

  // post login
  workspaces: '/workspaces',
  workspace: '/workspaces/:workspaceId',
  routes: '/workspaces/:id/routes',
  route: '/workspaces/:workspaceId/routes/:routeId',

  // user
  profile: '/profile',

}

export const BackendRoutes = {
  customMock: '/api/workspaces/:workspaceId/mock/:mockPath',
}
