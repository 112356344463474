import cn from 'classnames';
import { MouseEvent, useCallback } from 'react';

import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { IWorkspaceList } from '../../models/workspace.model';
import { StyledWorkspace } from './workspace-card.styled';


export interface WorkspaceProps {
  workspace: IWorkspaceList;
  className: string;
  onClick: (workspace: IWorkspaceList) => void;
  onEdit: (workspace: IWorkspaceList) => void;
}

export const WorkspaceCard = ({ className, workspace, onClick, onEdit }: WorkspaceProps) => {

  const clickHandler = useCallback(() => {
    onClick?.(workspace);
  }, [onClick, workspace])

  const handleEdit = useCallback((event: MouseEvent) => {
    event.stopPropagation();
    onEdit?.(workspace);
  }, [onEdit, workspace]);

  return (
    <StyledWorkspace className={cn('workspace-card', className)} onClick={clickHandler}>
      <div className="edit-icon" onClick={handleEdit}>
        <EditIcon className="icon" />
      </div>
      <div className="workspace-name">{workspace.name}</div>
      <div className="workspace-description">{workspace.description}</div>
      <div className="shared-with">
        {/* <SharedBar groups={workspace.sharedWith} /> */}
      </div>
    </StyledWorkspace>
  )
}