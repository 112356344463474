export interface AppTheme {
  colors: {
    primary: string;
    primaryAccent: string;
    text: string;
    background: string;
    hover: string;
    disabled: string;
    secondary: string;
    border: string;
    white: string;
    black: string;
    error: string;
  },
  shadows: {
    primary: string;
    hover: string;
    small: string;
  },
  avatar: string[];
}

export const mainTheme: AppTheme = {
  colors: {
    primary: '#7561f6',
    primaryAccent: '#fff',
    text: '#2b2b2b',
    background: '#f3f7fa',
    hover: '#f8f6ff',
    disabled: '#ececec',
    secondary: '#616161',
    border: '#ececec',
    white: '#ffffff',
    black: '#000000',
    error: '#e53935',
  },
  shadows: {
    primary: 'rgb(0 0 0 / 25%) 0px 4px 13px 0px',
    hover: 'rgb(0 0 0 / 25%) 0px 4px 16px 0px',
    small: 'rgb(0 0 0 / 25%) 0px 2px 5px 0px',
  },
  avatar: ["#7561f6", "#146A7C", "#e53935", "#C271B4", "#FFB350"],
}

export const appTheme = (handler: (theme: AppTheme) => string) => (props: { theme: AppTheme }): string => {
  if (props && props.theme) {
    return handler(props.theme);
  }
  return '';
}