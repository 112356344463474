import styled from 'styled-components';

import { appTheme } from '../../styles/theme.styled';

export const StyledToast = styled.div`
  position: absolute;
  bottom: 1rem;
  left: 50%;
  padding: 1rem 2rem;
  border-radius: 6px;
  background-color: ${appTheme(theme => theme.colors.primary)};
  color: ${appTheme(theme => theme.colors.primaryAccent)};
  transform: translate(-50%, 1rem);
  opacity: 0;
  box-shadow: ${appTheme(theme => theme.shadows.small)};
  pointer-events: none;
  user-select: none;
  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;

  transition: transform .3s ease, opacity .3s ease, box-shadow .3s ease;

  &:hover {
    .close-icon {
      transform: scale(125%);
    }
  }

  &.visible {
    transform: translate(-50%, 0rem);
    opacity: 1;
    box-shadow: ${appTheme(theme => theme.shadows.hover)};
    pointer-events: initial;
  }

  .message {
    margin-right: 1rem;
    font-weight: bold;
  }

  .close-icon {
    cursor: pointer;
    height: 0.75rem;
    fill: ${appTheme(theme => theme.colors.primaryAccent)};
    transition: transform .3s ease;
  }
`;
