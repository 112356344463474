import styled from "styled-components"
import { appTheme } from "../../styles/theme.styled";

export const StyledLoginPageLayout = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${appTheme((theme) => theme.colors.background)};

  .login-page-layout-container {
    max-width: 400px;
    width: 100%;
  }
`;