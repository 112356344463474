import cn from 'classnames';

import { StyledLoader, StyledLoaderAnimation } from './loader.styled';

interface BaseLoaderProps {
  loading: boolean;
  children: JSX.Element;
}

export interface LoaderProps extends BaseLoaderProps {
  unmount?: boolean;
}

export const Loader = ({ loading, unmount, children }: LoaderProps) => {
  const Component = unmount ? UnmountLoader : HiddenLoader;

  if (unmount) {
    return (
      <Component loading={loading}>
        {children}
      </Component>
    );
  }
  return (
    <StyledLoader className={cn({ loading })}>
      {children}
    </StyledLoader>
  );
}

const HiddenLoader = ({ children }: BaseLoaderProps) => {
  return (
    <>
      <LoaderAnimation />
      {children}
    </>
  );
}

const UnmountLoader = ({ loading, children }: BaseLoaderProps) => {
  return loading ? <LoaderAnimation /> : children;
}

export interface LoaderAnimationProps {
  light?: boolean;
  scale?: number;
}

export const LoaderAnimation = ({ light, scale = 1 }: LoaderAnimationProps) => {
  return (
    <StyledLoaderAnimation className={cn('loader-animation', { light })} scale={scale}>
      <div />
      <div />
      <div />
    </StyledLoaderAnimation>
  );
}