import cn from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { verifyEmail } from '../../api/login.api';
import { ReactIf } from '../../components/react-if/react-if.component';
import { Routes } from '../../router/routes';
import { callApi, useQuery } from '../../utils/make-api.util';
import { StyledEmailVerification } from './email-verification.styled';

export interface EmailVerificationProps {
  className?: string;
}

export const EmailVerification = ({ className }: EmailVerificationProps) => {
  const { t } = useTranslation();
  const { verificationToken } = useQuery();
  const history = useHistory();

  const [checking, setChecking] = useState(true);
  const [error, setError] = useState(false);

  console.log(verificationToken);


  useEffect(() => {
    callApi(
      verifyEmail(verificationToken),
      () => {
        history.push(Routes.workspaces);
      },
      () => {
        setError(true);
      }
    )
      .then(() => {
        setChecking(false);
      });
  }, [history, verificationToken]);


  return (
    <StyledEmailVerification className={cn('email-verification', className)}>
      <ReactIf condition={checking}>
        {t('signup:email-verification')}
      </ReactIf>
      <ReactIf condition={error}>
        {t('signup:errors:verification')}
      </ReactIf>
    </StyledEmailVerification>
  );
}