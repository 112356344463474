import styled from "styled-components";

export const StyledModalAddRoute = styled.div`
  .field {
    margin-bottom: 1rem;
  }

  .add-action {
    width:100%;
  }

`;
