import styled from "styled-components";

export const StyledModalChangePassword = styled.div`

  .field {
    margin-bottom: 1rem;
  }

  .change-password {
    width:100%;
  }
`;
