import { StyledCheckEmail } from "./check-email.styled"

import { callApi, useQuery } from '../../utils/make-api.util';
import { useEffect, useState } from "react";
import { checkEmail } from "../../api/login.api";

export const CheckEmail = () => {

  const { verificationToken } = useQuery();

  const [checking, setChecking] = useState(true);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      callApi(
        checkEmail(verificationToken),
        () => setSuccess(true),
        () => setSuccess(false)
      )
        .then(() => {
          setChecking(false);
        });
    }, 5000)
  }, [verificationToken]);

  return (
    <StyledCheckEmail>
      {checking ? 'Sto facendo un check...' : `Check completato (${success})!`}
    </StyledCheckEmail>
  )
}