import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { signup } from '../../api/login.api';

import { Button } from '../../components/button/button.component';
import { Input } from '../../components/input/input.component';
import { LoginPageLayout } from '../../components/login-page-layout/login-page-layout.component';
import { Routes } from '../../router/routes';
import { useAuth } from '../../store/auth.context';
import { checkEmail, checkPassword } from '../../utils/form.util';
import { callApi } from '../../utils/make-api.util';
import { StyledSignup } from './signup.styled';
import { ReactComponent as Logo } from '../../assets/images/mockingbird-logo.svg';
import { ReactComponent as ChevronLeft } from '../../assets/icons/chevron-left.svg';
import { useTranslation } from 'react-i18next';

export const Signup = () => {

  const { t } = useTranslation();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastSurname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [canSignup, setCanSignup] = useState(false);

  const history = useHistory();
  const { updateUser } = useAuth();

  useEffect(() => {
    let signup = firstName !== ''
      && lastName !== ''
      && checkEmail(email)
      && checkPassword(password)
      && password === confirmPassword;
    setCanSignup(signup);
  }, [firstName, lastName, email, password, confirmPassword]);

  useEffect(() => {
    const scriptTag = document.createElement("script");
    scriptTag.src = "https://www.google.com/recaptcha/api.js?render=6Ld1YJwaAAAAAP8YcTOP0tEul1OD8EnKRXuMcmw_";
    scriptTag.async = true;
    document.body.appendChild(scriptTag);
  }, []);

  const handleSignup = (e: any) => {
    e.preventDefault();

    const grecaptcha = (window as any).grecaptcha;

    grecaptcha.ready(function () {
      grecaptcha.execute('6Ld1YJwaAAAAAP8YcTOP0tEul1OD8EnKRXuMcmw_', { action: 'submit' }).then((recaptchaToken: any) => {
        callApi(
          signup(firstName, lastName, email, password, recaptchaToken),
          (user) => {
            updateUser(user);
            history.push(Routes.workspaces);
          },
        )
      });
    });
  }

  const goBack = () => history.goBack()

  return (
    <StyledSignup>
      <LoginPageLayout>
        <div className="logo">
          <Logo />
        </div>
        <div className="page-title-container">
          <div className="go-back" onClick={goBack}>
            <ChevronLeft />
          </div>
          <h1 className="page-title">
            {t('signup:signup')}
          </h1>
        </div>
        <div className="signup-container">
          <div className="name-surname">
            <Input
              className="name-input"
              value={firstName}
              name="name"
              label={t('signup:first-name')}
              onChange={setFirstName}
            />
            <Input
              className="surname-input"
              value={lastName}
              name="surname"
              label={t('signup:last-name')}
              onChange={setLastSurname}
            />
          </div>
          <Input
            value={email}
            name="email"
            label={t('signup:email')}
            onChange={setEmail}
          />
          <Input
            value={password}
            name="password"
            type="password"
            label={t('signup:password')}
            onChange={setPassword}
          />
          <Input
            className="confirm-password"
            value={confirmPassword}
            name="confirm-password"
            type="password"
            label={t('signup:confirm-password')}
            onChange={setConfirmPassword}
          />
          <Button
            className="signup-action"
            disabled={!canSignup}
            onClick={handleSignup}
          >
            {t('signup:signup')}
          </Button>
        </div>
      </LoginPageLayout>
    </StyledSignup>
  )
}