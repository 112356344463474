import styled from "styled-components";

import { appTheme } from '../../styles/theme.styled';

export const StyledInput = styled.div`

  label {
    margin-left: 1rem;
    margin-bottom: 0.5rem;
    display: block;
  }

  input {
    outline: none;
    display: block;
    padding: 1rem;
    border-radius: 4px;
    box-shadow: ${appTheme((theme) => theme.shadows.primary)};
    width: 100%;
    border: 1px solid transparent;

    :focus {
      border: 1px solid ${appTheme((theme) => theme.colors.primary)};
    }
  }
`;