import styled from "styled-components";
import { appTheme } from "../../styles/theme.styled";

export const StyledTokensTable = styled.div`
  width: 100%;

  .token-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    font-size: 1.25rem;
    font-weight: bold;

    padding-bottom: 0.5rem;
    border-bottom: 1px solid ${appTheme(theme => theme.colors.border)};
    margin-bottom: 0.5rem;
    padding: 0.5rem 1rem;

    .header-cell {
      &:last-child {
        margin-right: calc(16px + 0.5rem);
      }
    }
  }

  .token-content {

    /* max-height: 10.5rem;
    overflow: auto; */

    .token-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0.5rem 1rem;
  
      &:nth-child(even) {
        background-color: ${appTheme(theme => theme.colors.disabled)};
      }
  
      .row-content {
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-right: 0.5rem;
  
      }
      .row-actions {
  
        .remove-icon {
          width: 16px;
          cursor: pointer;
          fill: ${appTheme(theme => theme.colors.error)}
        }
      }
  
    }
  }

`;
