import styled from "styled-components";
import { appTheme } from "../../styles/theme.styled";

export const StyledRouteCard = styled.div`
  background-color: ${appTheme((theme) => theme.colors.primaryAccent)};
  border-radius: 4px;
  padding: 1rem;
  cursor: pointer;
  box-shadow: ${appTheme((theme) => theme.shadows.primary)};
  transition: box-shadow .3s ease, transform .3s ease;

  :hover {
    background-color: ${appTheme((theme) => theme.colors.hover)};
    transform: translateY(-2px);
    box-shadow: ${appTheme((theme) => theme.shadows.hover)};
  }

  .route-name {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    font-weight: bold;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;