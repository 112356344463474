import { StyledLoginPageLayout } from './login-page-layout.styled';

export interface LoginPageLayoutProps {
  children: JSX.Element | JSX.Element[];
}

export const LoginPageLayout = ({ children }: LoginPageLayoutProps) => {
  return (
    <StyledLoginPageLayout className="login-page-layout">
      <div className="login-page-layout-container">
        {children}
      </div>
    </StyledLoginPageLayout>
  )
}