import cn from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { createWorkspace, updateWorkspace } from '../../../../api/workspaces.api';
import { useModal } from '../../../../store/modal.context';
import { callApi } from '../../../../utils/make-api.util';
import { Button } from '../../../button/button.component';
import { Input } from '../../../input/input.component';
import { ModalActions } from '../modal-actions/modal-actions.component';
import { ModalContent } from '../modal-content/modal-content.component';
import { ModalHeader } from '../modal-header/modal-header.component';
import { StyledModalAddWorkspace } from './modal-add-workspace.styled';

export interface ModalAddWorkspaceProps {
  className?: string;
}

export const ModalAddWorkspace = ({ className }: ModalAddWorkspaceProps) => {

  const { hide, data } = useModal();
  const { t } = useTranslation();

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const isEdit = !!data?.workspace;

  useEffect(() => {
    if (isEdit) {
      setName(data.workspace.name);
      setDescription(data.workspace.description);
    }
  }, [data?.workspace.description, data?.workspace?.name, isEdit]);

  const handleAddWorkspace = useCallback(() => {
    callApi(
      createWorkspace(name, description),
      (workspace) => {
        hide(workspace);
      }
    );
  }, [name, description, hide]);

  const handleEditWorkspace = useCallback(() => {
    callApi(
      updateWorkspace(data.workspace.id, { name, description }),
      (workspace) => {
        hide(workspace);
      }
    );
  }, [data?.workspace.id, name, description, hide]);

  return (
    <StyledModalAddWorkspace className={cn('modal-add-workspace', className)}>
      <ModalHeader close={true}>
        {
          isEdit
            ? t('workspaces:edit-workspace')
            : t('workspaces:add-workspace')
        }
      </ModalHeader>
      <ModalContent>
        <Input
          className="field"
          label={t('workspaces:name')}
          name="name"
          value={name}
          onChange={setName}
        />
        <Input
          className="field"
          label={t('workspaces:description')}
          name="description"
          value={description}
          onChange={setDescription}
        />
      </ModalContent>
      <ModalActions>
        {
          isEdit
            ? (
              <Button
                className="add-action"
                onClick={handleEditWorkspace}
              >
                {t('workspaces:edit')}
              </Button>
            )
            : (
              <Button
                className="add-action"
                onClick={handleAddWorkspace}
              >
                {t('workspaces:add')}
              </Button>
            )
        }
      </ModalActions>
    </StyledModalAddWorkspace>
  );
}