import styled from 'styled-components';

import { appTheme } from '../../styles/theme.styled';

export const StyledForgotPassword = styled.div`
  height: 100%;

  .logo {
    margin-bottom: 2rem;
  }

  .page-title-container {
    position: relative;

    .go-back {
      height: 1rem;
      position: absolute;
      top: 50%;
      left: 1rem;
      transform: translateY(-50%);
      cursor: pointer;

      svg {
        height: 100%;
        fill: ${appTheme(theme => theme.colors.primary)}
      }
    }

    .page-title {
      text-align: center;
  
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: 1rem;
    }
  }

  .forgot-password-form {
    margin-bottom: 2rem;
  }

  .forgot-password-actions {
    margin-bottom: 2rem;
    position: relative;

    button {
      width: 100%;
    }

    .message {
      position: absolute;
      bottom: 0;
      transform: translateY(200%);
      color: ${appTheme((theme) => theme.colors.error)};
    }
  }

`;