import styled from "styled-components";
import { appTheme } from "../../styles/theme.styled";

export const StyledEmailVerification = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: ${appTheme(theme => theme.colors.background)};

  font-size: 1.5rem;
`;
