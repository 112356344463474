import styled from "styled-components";
import { appTheme } from "../../styles/theme.styled";

export const StyledWorkspace = styled.div`
  position: relative;
  border: 1px solid ${appTheme((theme) => theme.colors.border)};
  padding: 1rem;

  width: 300px;
  height: 200px;

  text-decoration: none;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  border-radius: 4px;
  box-shadow: ${appTheme((theme) => theme.shadows.primary)};
  background-color: ${appTheme((theme) => theme.colors.white)};
  cursor: pointer;

  transition: box-shadow .3s ease;

  &:hover {
    box-shadow: ${appTheme((theme) => theme.shadows.hover)};
  }

  .edit-icon {
    position: absolute;
    top: 1rem;
    right: 1rem;
    height: 1rem;

    .icon {
      height: 100%;
      fill: ${appTheme((theme) => theme.colors.primary)};
    }
  }

  .workspace-name {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  .workspace-description {
    color: ${appTheme((theme) => theme.colors.secondary)};
  }

  .shared-with {
    justify-self: flex-end;
    width: 100%;
  }
`;