import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

import { postLogin } from '../../api/login.api';
import { ReactComponent as Logo } from '../../assets/images/mockingbird-logo.svg';
import { Button } from '../../components/button/button.component';
import { Input } from '../../components/input/input.component';
import { Routes } from '../../router/routes';
import { useAuth } from '../../store/auth.context';
import { callApi } from '../../utils/make-api.util';
import { StyledLogin } from './login.styled';

export const Login = () => {

  const { t } = useTranslation();
  const history = useHistory()

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [isError, setIsError] = useState<Error>();
  const [isLoading, setIsLoading] = useState(false);

  const { updateUser } = useAuth();

  const handleLogin = () => {
    setIsLoading(true);
    setIsError(undefined);
    callApi(
      postLogin(email, password),
      (user) => {
        setIsLoading(false);
        updateUser(user);
        history.push(Routes.workspaces);
      },
      (error) => {
        setIsLoading(false);
        setIsError(error);
      }
    );
  }

  return (
    <StyledLogin>
      <div className="login-container">
        <div className="logo">
          <Logo />
        </div>
        <div className="login-form">
          <Input
            className="login-input"
            value={email}
            onChange={setEmail}
            name="email"
            label={t('login:email')}
            placeholder={t('login:email-placeholder')}
          />
          <Input
            className="login-input"
            value={password}
            onChange={setPassword}
            name="email"
            label={t('login:password')}
            placeholder={t('login:password-placeholder')}
            type="password"
          />
          {(isError && !isLoading) && <div className="error-container">Errore durante il login.</div>}
          <Button className="login-action" onClick={handleLogin} loading={isLoading}>
            {t('login:login')}
          </Button>
        </div>
        <div className="actions">
          <Link to={Routes.forgotPassword}>
            {t('login:forgot-password')}
          </Link>
          <Link to={Routes.signup}>
            {t('login:signup')}
          </Link>
        </div>
      </div>
    </StyledLogin>
  )
}