import styled from "styled-components";
import { appTheme } from "../../styles/theme.styled";

export const StyledNewWorkspaceCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 200px;

  border: dashed 2px ${appTheme((theme) => theme.colors.border)};
  border-radius: 4px;
  cursor: pointer;
  user-select: none;

  .new-workspace-label {
    font-weight: bold;
    margin-bottom: 1rem;

    color: ${appTheme((theme) => theme.colors.primary)};
  }

  .add-icon > svg {
    height: 1.5rem;
    fill: ${appTheme((theme) => theme.colors.primary)};

  }
`;
