import styled from "styled-components";
import { appTheme } from "../../styles/theme.styled";

export const StyledModal = styled.div`

  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${appTheme((theme) => theme.colors.black)}6b;
  opacity: 0;
  pointer-events: none;
  transition: opacity .3s ease;

  .modal-container {
    position: absolute;
    top: calc(50% + 20px);
    left: 50%;
    background-color: ${appTheme((theme) => theme.colors.white)};
    border: 1px solid ${appTheme((theme) => theme.colors.border)};
    box-shadow: ${appTheme((theme) => theme.shadows.primary)};
    transform: translate(-50%, -50%);
    transition: top .3s ease;
    padding: 1rem;
    border-radius: 4px;
    min-width: 350px;
  }

  &.show {
    opacity: 1;
    pointer-events: initial;

    .modal-container {
      top: 50%;
    }
  }

`;
