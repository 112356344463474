import { rgba } from "polished";
import styled from "styled-components";
import { appTheme } from "../../../../styles/theme.styled";

export const StyledUserDropdown = styled.div`
  .user-dropdown-content {
    .user-name {
      padding: 1rem;
      width: 100%;
      font-size: 1.5rem;
      color: ${appTheme((theme) => theme.colors.primary)};
      font-weight: bold;
      white-space: nowrap;
      border-bottom: 1px solid ${appTheme((theme) => rgba(theme.colors.primary, 0.5))};
    }

    .menu {
      padding: 0.5rem 0;
      /* border-bottom: 1px solid ${appTheme((theme) => rgba(theme.colors.primary, 0.5))}; */

      .menu-item {
        padding: 0.5rem 1rem;
        font-weight: bold;

        &:hover {
          background-color: ${appTheme((theme) => rgba(theme.colors.primary, 0.15))}
        }
      }
    }

    .logout {
      padding: 1rem;
      font-weight: bold;

      &:hover {
          background-color: ${appTheme((theme) => rgba(theme.colors.primary, 0.15))}
        }
    }
  }
`;