import { IUpdateWorkspace, IWorkspaceDetails, IWorkspaceList } from '../models/workspace.model';
import { authHttp } from './http';

export const getWorkspaces = (): Promise<IWorkspaceList[]> => {
  return authHttp.get('/workspaces');
}

export const getWorkspace = (workspaceId: string): Promise<IWorkspaceDetails> => {
  return authHttp.get(`/workspaces/${workspaceId}`);
}

export const createWorkspace = (name: string, description: string): Promise<IWorkspaceDetails> => {
  return authHttp.post('/workspaces', { name, description });
}

export const updateWorkspace = (workspaceId: string, payload: IUpdateWorkspace): Promise<IWorkspaceDetails> => {
  return authHttp.post(`/workspaces/${workspaceId}`, payload);
}