import { Token } from '../models/token.model';
import { authHttp } from './http';

export const getTokens = (): Promise<Token[]> => {
  return authHttp.get('/tokens');
}

export const createToken = (label: string): Promise<Token> => {
  return authHttp.post('/tokens', { label });
}

export const removeToken = (token: Token): Promise<void> => {
  return authHttp.delete(`/tokens/${token.id}`);
}