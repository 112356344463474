import cn from 'classnames';

import { StyledNewWorkspaceCard } from './new-workspace-card.styled';

import { ReactComponent as AddBorderIcon } from '../../assets/icons/add-border.svg';
import { useTranslation } from 'react-i18next';

export interface NewWorkspaceCardProps {
  onClick: () => void;
  className?: string;
}

export const NewWorkspaceCard = ({ className, onClick }: NewWorkspaceCardProps) => {

  const { t } = useTranslation()

  return (
    <StyledNewWorkspaceCard className={cn('new-workspace-card', className)} onClick={onClick}>
      <div className="new-workspace-label">
        {t('workspaces:new')}
      </div>
      <div className="add-icon">
        <AddBorderIcon />
      </div>
    </StyledNewWorkspaceCard>
  );
}