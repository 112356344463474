import styled from "styled-components";
import { appTheme } from "../../styles/theme.styled";

export const StyledLoader = styled.div<{ loading?: boolean }>`

  height: 100%;
  width: 100%;

  .loader-animation {
    display: none;
  }

  .loading {
    > * {
      display: none;
    }

    .loader-animation {
      display: block;
    }
  }
`;

export const StyledLoaderAnimation = styled.span<{ scale: number; }>`

  &,
  & > div {
    position: relative;
    box-sizing: border-box;
  }

  & {
    display: block;
    font-size: 0;
    color: #7561f6;
    &.light, &.light > * {
      color: ${appTheme((theme) => theme.colors.primaryAccent)};
    }
  }

  & > div {
    display: inline-block;
    float: none;
    background-color: currentColor;
    border: 0 solid currentColor;
  }

  & {
    width: ${(props) => props.scale}rem;
    height: ${(props) => props.scale}rem;
  }

  & > div {
    position: absolute;
    top: 0;
    left: 0;
    width: ${(props) => props.scale}rem;
    height: ${(props) => props.scale}rem;
    background: transparent;
    border-width: 2px;
    border-radius: 100%;
    opacity: 0;
    animation: ball-scale-ripple-multiple 1.25s 0s infinite cubic-bezier(.21, .53, .56, .8);
  }

  & > div:nth-child(1) {
    animation-delay: 0s;
  }

  & > div:nth-child(2) {
    animation-delay: .25s;
  }

  & > div:nth-child(3) {
    animation-delay: .5s;
  }

  /*
  * Animation
  */
  @keyframes ball-scale-ripple-multiple {
      0% {
        opacity: 1;
        transform: scale(.1);
      }
      70% {
        opacity: .5;
        transform: scale(1);
      }
      95% {
        opacity: 0;
      }
  }
`;