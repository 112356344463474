import styled from "styled-components";

export const StyledModalActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 1rem;

  > *:not(:first-child) {
    margin-left: 1rem;
  }
`;
