import { createContext, useContext, useState } from 'react';
import { IUser } from '../models/user.model';

export interface IAuthContext {
  user: IUser;
  updateUser: (user: IUser) => void;
}

const initialState = {
  user: null,
  token: null,
} as any;

const AuthContext = createContext(initialState);

export interface AuthProviderProps {
  children: JSX.Element;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [user, setUser] = useState(null);

  const value = {
    user,
    updateUser: setUser,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = (): IAuthContext => {

  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider')
  }
  return context
}