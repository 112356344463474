import cn from 'classnames';

import { StyledModalActions } from './modal-actions.styled';

export interface ModalActionsProps {
  children: JSX.Element | JSX.Element[];
  className?: string;
}

export const ModalActions = ({ className, children }: ModalActionsProps) => {
  return (
    <StyledModalActions className={cn('modal-actions', className)}>
      { children}
    </StyledModalActions>
  );
}