import MonacoEditor from '@monaco-editor/react';
import { useCallback } from 'react';

import { StyledJsonEditor } from './json-editor.styled';

export interface JsonEditorProps {
  onLoad: () => void;
  onChange: (value: string | undefined, event: any) => void;
  value: string;
}

export const JsonEditor = ({ value, onLoad, onChange }: JsonEditorProps) => {
  const overloadOnLoad = useCallback((editor, monaco) => {
    editor.focus();
    onLoad?.();
  }, [onLoad]);

  const beforeEditorMount = useCallback((monaco) => {
    monaco.editor.defineTheme('mockingbird', {
      base: 'vs', // can also be vs-dark or hc-black
      inherit: true, // can also be false to completely replace the builtin rules
      rules: [],
      colors: {},
    })
  }, []);

  return (
    <StyledJsonEditor>
      <MonacoEditor
        language="json"
        theme="mockingbird"
        value={value}
        options={{
          selectOnLineNumbers: true,
          folding: true,
          fontSize: 14,
          formatOnType: true,
          matchBrackets: 'always',
          autoClosingBrackets: 'always',
          autoClosingQuotes: 'always',
          autoClosingOvertype: 'always',
          cursorBlinking: 'smooth',
          minimap: {
            enabled: false,
          },
        }}
        onChange={onChange}
        onMount={overloadOnLoad}
        beforeMount={beforeEditorMount}
      />
    </StyledJsonEditor>
  )
}