import cn from 'classnames';
import { MouseEvent, useCallback, useEffect } from 'react';

import { useModal } from '../../store/modal.context';
import { ModalRegistry } from './modal.registry';
import { StyledModal } from './modal.styled';

export const Modal = () => {

  const {
    hide,
    isShown,
    data,
    modal,
  } = useModal();

  const handleClickBackground = useCallback((event: MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      hide();
    }
  }, [hide]);

  useEffect(() => {
    const onEnter = (event: KeyboardEvent) => {
      if (document.body.isSameNode(event.target as Node) && event.keyCode === 27) {
        hide();
      }
    };

    document.addEventListener('keydown', onEnter)
    return () => {
      document.removeEventListener('keydown', onEnter);
    }
  }, [hide]);

  const ModalComponent = modal ? ModalRegistry[modal] : () => null;

  return (
    <StyledModal className={cn('modal-overlay', { show: isShown })} onMouseDown={handleClickBackground}>
      <div className="modal-container">
        {
          modal && (
            <ModalComponent data={data} />
          )
        }
      </div>
    </StyledModal >
  );
}