import cn from 'classnames';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { removeToken } from '../../api/token.api';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg';
import { Token } from '../../models/token.model';
import { useModal } from '../../store/modal.context';
import { useToast } from '../../store/toast.context';
import { callApi } from '../../utils/make-api.util';
import { CopyLabel } from '../copy-label/copy-label.component';
import { DialogResult } from '../modal/components/modal-dialog/modal-dialog.component';
import { ModalName } from '../modal/modal.registry';
import { StyledTokensTable } from './tokens-table.styled';

export interface TokensTableProps {
  className?: string;
  tokens: Token[];
  onRefresh: () => void;
}

export const TokensTable = ({ className, tokens, onRefresh }: TokensTableProps) => {

  const { t } = useTranslation();
  const { show: showToast } = useToast();
  const modal = useModal();

  const handleCopyUrl = useCallback((token: string = '') => {
    navigator.clipboard.writeText(token);
    showToast(t('routes:copy-clipboard'));
  }, [showToast, t])

  const onRemoveHandler = useCallback(async (token) => {
    const result: DialogResult = await modal.show(ModalName.DIALOG, {
      message: t('profile:token:remove-message'),
      title: t('profile:token:remove-title'),
    });

    if (result === DialogResult.CONFIRM) {
      callApi(
        removeToken(token),
        onRefresh,
      )
    }

  }, [modal, onRefresh, t]);

  return (
    <StyledTokensTable className={cn('tokens-table', className)}>
      <div className="token-header">
        <div className="header-cell">{t('profile:token:label')}</div>
        <div className="header-cell">{t('profile:token:value')}</div>
      </div>
      <div className="token-content">

        {tokens.map((token) => (
          <div key={token.id} className="token-row">
            <div className="row-content">

              <div className="token-cell">{token.label}</div>
              <div className="token-cell">
                <CopyLabel value={token.token} onCopy={handleCopyUrl}>
                  {token.token}
                </CopyLabel>
              </div>
            </div>
            <div className="row-actions">
              <TrashIcon className="remove-icon" onClick={() => onRemoveHandler(token)} />
            </div>
          </div>
        ))}
      </div>
    </StyledTokensTable>
  );
}