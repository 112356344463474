import { useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router';

import { getMe } from './api/login.api';
import { Loader } from './components/loader/loader.component';
import { Login } from './pages/login/login.component';
import { WorkspaceDetails } from './pages/workspace-details/workspace-details.component';
import { Workspaces } from './pages/workspaces/workspaces.component';
import { Routes } from './router/routes';
import { useAuth } from './store/auth.context';
import Reset from './styles/reset.styled';
import Global from './styles/global.styled';
import { callApi } from './utils/make-api.util';
import { ForgotPassword } from './pages/forgot-password/forgot-password.component';
import { Signup } from './pages/signup/signup.component';
import { PageLayout } from './components/page-layout/page-layout.component';
import { CheckEmail } from './pages/check-email/check-email.component';
import { Modal } from './components/modal/modal.component';
import { ChangePassword } from './pages/change-password/change-password.component';
import { Profile } from './pages/profile/profile.component';
import { EmailVerification } from './pages/email-verification/email-verification.component';
import { Toast } from './components/toast/toast.component';

function App() {

  const [isAuthenticating, setIsAuthenticating] = useState(true);

  const { user, updateUser } = useAuth();

  useEffect(() => {
    callApi(
      getMe(),
      (user) => {
        updateUser(user);
      },
    )
      .then(() => {
        setIsAuthenticating(false);
      });
  }, [updateUser]);

  return (
    <div className="mockingbird">
      <Reset />
      <Global />
      <Loader loading={isAuthenticating} unmount={true}>
        <Switch>
          <Route exact path={Routes.emailVerification} component={EmailVerification} />
          {
            !!user
              ? (
                <PageLayout>
                  <Switch>
                    {/* Logged in */}
                    <Route exact path={[Routes.workspace, Routes.route]} component={WorkspaceDetails} />
                    <Route exact path={Routes.workspaces} component={Workspaces} />
                    <Route exact path={Routes.profile} component={Profile} />
                    <Redirect to={Routes.workspaces} />
                  </Switch>
                </PageLayout>
              ) : (
                <Switch>
                  {/* Login */}
                  <Route exact path={Routes.login} component={Login} />
                  <Route exact path={Routes.forgotPassword} component={ForgotPassword} />
                  <Route exact path={Routes.signup} component={Signup} />
                  <Route exact path={Routes.checkEmail} component={CheckEmail} />
                  <Route exact path={Routes.changePassword} component={ChangePassword} />
                  <Redirect to={Routes.login} />
                </Switch>
              )
          }
        </Switch>
      </Loader>
      <Modal />
      <Toast />
    </div>
  );
}

export default App;
