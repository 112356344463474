import styled from 'styled-components';
import { appTheme } from '../../styles/theme.styled';

export const StyledLogin = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${appTheme((theme) => theme.colors.background)};

  .login-container {
    width: 400px;

    .logo {
      margin: auto;
      margin-bottom: 2rem;
      width: 90%;
    }

    .login-input {
      margin-bottom: 1rem;

      &:last-of-type {
        margin-bottom: 2rem;
      }
    }

    .login-action {
      margin-bottom: 1rem;
      width: 100%;
    }

    .error-container {
      color: ${appTheme(theme => theme.colors.error)};
      margin-bottom: 1rem;
    }

    .actions {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
`;