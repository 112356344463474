import styled from 'styled-components';

import { appTheme } from '../../styles/theme.styled';

export const StyledPageLayout = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${appTheme((theme) => theme.colors.background)};

  .header {
    height: 100%;
    height: 4rem;
    padding: 0.5rem 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .app-logo {
      height: 100%;
      cursor: pointer;

      svg {
        height: 100%;
      }
    }
    .user {

      display: flex;
      flex-direction: row;
      align-items: center;

      .user-name {
        margin-right: 1rem
      }

      .user-icon {
        border: 2px solid ${appTheme((theme) => theme.colors.primary)};
        padding: 2px;
        border-radius: 50%;
        width: 48px;
        height: 48px;
      }
    }
  }

  .page-content {
    flex-grow: 1;
    align-self: center;
    height: 0;
    max-width: 1440px;
    width: 100%;
    padding: 1rem;
    overflow: auto;
  }
`;