import cn from 'classnames';

import { IRoute } from '../../models/route.model';
import { StyledRouteCard } from './route-card.styled';

export interface RouteCardProps {
  route: IRoute;
  className?: string;
  selected?: boolean;
  onClick?: () => void;
}

export const RouteCard = ({ className, route, onClick, selected }: RouteCardProps) => {
  return (
    <StyledRouteCard
      className={cn(className, { selected })}
      onClick={onClick}
    >
      <div className="route-name">{route.name}</div>
      <div className="route-description">{route.description}</div>
    </StyledRouteCard>
  )
}