import { createGlobalStyle } from "styled-components";
import { appTheme } from "./theme.styled";

export default createGlobalStyle`

  .mockingbird {
    height: 100vh;
    width: 100vw;
  }

  body {
    font-family: 'Nunito', sans-serif;
    font-size: 1rem;
    color: ${appTheme((theme) => theme.colors.text)};
  }

  /* svg {
    width: 100%;
  } */

  a {
    color: ${appTheme((theme) => theme.colors.primary)};
  }

  b {
    font-weight: bold;
  }

  @font-face {
    font-family: 'Nunito';
    src: url('/assets/fonts/Nunito-Light.eot');
    src: url('/assets/fonts/Nunito-Light.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/Nunito-Light.woff2') format('woff2'),
        url('/assets/fonts/Nunito-Light.woff') format('woff'),
        url('/assets/fonts/Nunito-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito';
    src: url('/assets/fonts/Nunito-Bold.eot');
    src: url('/assets/fonts/Nunito-Bold.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/Nunito-Bold.woff2') format('woff2'),
        url('/assets/fonts/Nunito-Bold.woff') format('woff'),
        url('/assets/fonts/Nunito-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito';
    src: url('/assets/fonts/Nunito-Regular.eot');
    src: url('/assets/fonts/Nunito-Regular.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/Nunito-Regular.woff2') format('woff2'),
        url('/assets/fonts/Nunito-Regular.woff') format('woff'),
        url('/assets/fonts/Nunito-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

`;