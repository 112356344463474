import { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { forgotPassword } from '../../api/login.api';
import { ReactComponent as ChevronLeft } from '../../assets/icons/chevron-left.svg';
import { ReactComponent as Logo } from '../../assets/images/mockingbird-logo.svg';
import { Button } from '../../components/button/button.component';
import { Input } from '../../components/input/input.component';
import { LoginPageLayout } from '../../components/login-page-layout/login-page-layout.component';
import { checkEmail } from '../../utils/form.util';
import { callApi } from '../../utils/make-api.util';
import { StyledForgotPassword } from './forgot-password.styled';

export const ForgotPassword = () => {

  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [sent, setSent] = useState(false);

  const history = useHistory();

  useEffect(() => {
    setDisabled(!checkEmail(email));

  }, [email]);

  const handleSend = useCallback(() => {
    callApi(
      forgotPassword(email),
      () => {
        setSent(true);
      }
    )
  }, [email]);

  const goBack = () => history.goBack()

  return (
    <StyledForgotPassword>
      <LoginPageLayout>
        <div className="logo">
          <Logo />
        </div>
        <div className="page-title-container">
          <div className="go-back" onClick={goBack}>
            <ChevronLeft />
          </div>
          <h1 className="page-title">
            {t('forgot-password:title')}
          </h1>
        </div>
        <div className="forgot-password-form">
          <Input
            name="email"
            label={t('forgot-password:email')}
            placeholder={t('forgot-password:email-placeholder')}
            value={email}
            onChange={setEmail}
          />
        </div>
        <div className="forgot-password-actions">
          <Button disabled={disabled} onClick={handleSend}>
            {t('forgot-password:send')}
          </Button>
          {
            sent
              ? (
                <div className="message">
                  {t('forgot-password:sent-message')}
                </div>
              )
              : <></>
          }
        </div>
      </LoginPageLayout>
    </StyledForgotPassword>
  )
}