import { StyledReactIf } from './react-if.styled';

export interface ReactIfProps {
  children: JSX.Element | JSX.Element[];
  condition: boolean;
}

export const ReactIf = ({ condition, children }: ReactIfProps) => {
  return (
    <StyledReactIf>
      {condition ? children : null}
    </StyledReactIf>
  );
}