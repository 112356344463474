import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { getRoute, getRoutePayload, updateRoutePayload } from '../../api/routes.api';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { API_URL } from '../../env';
import { IRoute } from '../../models/route.model';
import { useModal } from '../../store/modal.context';
import { useToast } from '../../store/toast.context';
import { formatJSON, validateJSON } from '../../utils/json.util';
import { callApi } from '../../utils/make-api.util';
import { Button } from '../button/button.component';
import { CopyLabel } from '../copy-label/copy-label.component';
import { EmptyState } from '../empty-state/empty-state.component';
import { JsonEditor } from '../json-editor/json-editor.component';
import { Loader } from '../loader/loader.component';
import { ModalName } from '../modal/modal.registry';
import { StyledRouteDetails } from './route-details.styled';

export const RouteDetails = () => {
  const { t } = useTranslation();
  const { workspaceId, routeId } = useParams<{ routeId: string, workspaceId: string }>();
  const { show: showToast } = useToast();


  const [route, setRoute] = useState<IRoute>();
  const [routePayload, setRoutePayload] = useState<any>({});

  const [routeLoading, setRouteLoading] = useState(false);
  const [routePayloadLoading, setRoutePayloadLoading] = useState(false);

  const [canSave, setCanSave] = useState(false);

  useEffect(() => {
    if (routeId) {
      setRouteLoading(true);
      setRoutePayloadLoading(true);
      callApi(
        Promise.all([
          getRoute(routeId),
          getRoutePayload(routeId),
        ]),
        ([route, payload]) => {
          setRoute(route);
          setRoutePayload(JSON.stringify(payload, null, 2));
          setCanSave(true);
        },
      )
        .then(() => {
          setRouteLoading(false);
          setRoutePayloadLoading(false);
        })
    }
  }, [routeId]);

  const handleFormat = useCallback(() => {
    setRoutePayload(formatJSON(routePayload))
  }, [routePayload]);

  const handleSave = useCallback(() => {
    setRoutePayloadLoading(true);
    callApi(
      updateRoutePayload(routeId, routePayload),
      (updatedPayload) => {
        setRoutePayload(JSON.stringify(updatedPayload, null, 2));
      }
    )
      .then(() => {
        setRoutePayloadLoading(false);
      });
  }, [routeId, routePayload]);

  const handleChange = useCallback((data: string | undefined, event: any) => {
    setRoutePayload(data);
    setCanSave(validateJSON(String(data)));
  }, []);

  const { show } = useModal();

  const handleEditRoute = useCallback(async () => {
    const editedRoute = await show(ModalName.ADD_ROUTE, { route });
    if (editedRoute) {
      setRoute(editedRoute);
    }
  }, [route, show]);

  const handleCopyUrl = useCallback(() => {
    if (!route) {
      return;
    }

    let mockRoute = `${API_URL}/api/workspaces/${workspaceId}/mock`;
    const mock = route.mock || route.id;
    if (!mock.startsWith('/')) {
      mockRoute += '/';
    }
    mockRoute += mock;

    navigator.clipboard.writeText(mockRoute);

    showToast(t('routes:copy-clipboard'));

  }, [route, showToast, t, workspaceId]);


  return (
    <StyledRouteDetails>
      <Loader loading={routeLoading} unmount={true}>
        {
          routeId && route
            ? (
              <>
                <div className="edit-icon" onClick={handleEditRoute}>
                  <EditIcon className="icon" />
                </div>
                <div className="route-title">
                  <div className="title">{route.name}</div>
                </div>
                <div className="route-description">{route.description}</div>
                <CopyLabel onCopy={handleCopyUrl}>
                  {route.mock || t('routes:copy-route')}
                </CopyLabel>

                <div className="route-payload">
                  <div className="actions">
                    <Button
                      disabled={!canSave}
                      onClick={handleFormat}
                    >
                      {t('routes:format')}
                    </Button>
                    <Button
                      disabled={!canSave}
                      onClick={handleSave}
                      loading={routePayloadLoading}
                    >
                      {t('routes:save')}
                    </Button>
                  </div>
                  <div className="editor">
                    <JsonEditor
                      value={routePayload}
                      onChange={handleChange}
                      onLoad={console.log}
                    />
                  </div>
                </div>
              </>
            ) : (
              <EmptyState>
                {t('routes:empty-state')}
              </EmptyState>
            )
        }
      </Loader>
    </StyledRouteDetails>
  )
}