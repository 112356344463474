import { useCallback, useEffect, useState } from 'react';
import { generatePath, useHistory } from 'react-router';

import { getWorkspaces } from '../../api/workspaces.api';
import { ModalName } from '../../components/modal/modal.registry';
import { NewWorkspaceCard } from '../../components/new-workspace-card/new-workspace-card.component';
import { WorkspaceCard } from '../../components/workspace-card/workspace-card.component';
import { IWorkspaceList } from '../../models/workspace.model';
import { Routes } from '../../router/routes';
import { useModal } from '../../store/modal.context';
import { callApi } from '../../utils/make-api.util';
import { useReload } from '../../utils/reload.hook';
import { StyledWorkspaces } from './workspaces.styled';

export const Workspaces = () => {

  const [workspaces, setWorkspaces] = useState<IWorkspaceList[]>([]);
  const [reload, setReload] = useReload();
  const history = useHistory();
  const { show } = useModal();

  useEffect(() => {
    callApi(
      getWorkspaces(),
      (workspaces) => {
        setWorkspaces(workspaces);
      },
    );
  }, [reload])

  const handleWorkspaceClick = useCallback((workspace) => {
    history.push(generatePath(Routes.workspace, { workspaceId: workspace.id }))
  }, [history]);

  const handleAddWorkspace = useCallback(async () => {
    await show(ModalName.ADD_WORKSPACE);
    setReload();
  }, [setReload, show]);

  const handleEditWorkspace = useCallback(async (workspace) => {
    await show(ModalName.ADD_WORKSPACE, { workspace });
    setReload();
  }, [setReload, show])

  return (
    <StyledWorkspaces className="workspaces">
      {
        workspaces.map((workspace) => {
          return (
            <WorkspaceCard
              key={workspace.id}
              className="workspace"
              workspace={workspace}
              onClick={handleWorkspaceClick}
              onEdit={handleEditWorkspace}
            />
          )
        })
      }
      <NewWorkspaceCard onClick={handleAddWorkspace} />
    </StyledWorkspaces>
  )
}