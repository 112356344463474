import cn from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createRoute, updateRoute } from '../../../../api/routes.api';
import { useModal } from '../../../../store/modal.context';
import { callApi } from '../../../../utils/make-api.util';
import { Button } from '../../../button/button.component';
import { Input } from '../../../input/input.component';
import { ModalActions } from '../modal-actions/modal-actions.component';
import { ModalContent } from '../modal-content/modal-content.component';
import { ModalHeader } from '../modal-header/modal-header.component';

import { StyledModalAddRoute } from './modal-add-route.styled';

export interface ModalAddRouteProps {
  className?: string;
}

export const ModalAddRoute = ({ className }: ModalAddRouteProps) => {

  const { t } = useTranslation();
  const { hide, data } = useModal();

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [mock, setMock] = useState('');
  const [disabled, setDisabled] = useState(true);

  const isEdit = !!data.route;

  useEffect(() => {
    if (isEdit) {
      setName(data.route.name);
      setDescription(data.route.description);
      setMock(data.route.mock);
    }
  }, [data, isEdit]);

  useEffect(() => {
    setDisabled(name.length < 3);
  }, [name]);

  const handleAddRoute = useCallback(() => {

    callApi(
      createRoute(name, description, mock, data.workspace),
      (route) => {
        hide(route);
      }
    )

  }, [name, description, mock, data.workspace, hide]);

  const handleEditRoute = useCallback(() => {

    callApi(
      updateRoute(data.route.id, { name, description, mock }),
      (route) => {
        hide(route);
      }
    )

  }, [data?.route?.id, description, hide, mock, name])

  return (
    <StyledModalAddRoute className={cn('modal-add-route', className)}>
      <ModalHeader close={true}>
        {
          isEdit
            ? t('routes:edit-route')
            : t('routes:add-route')
        }
      </ModalHeader>
      <ModalContent>
        <Input
          className="field"
          label={`${t('routes:name')}*`}
          name="name"
          value={name}
          onChange={setName}
        />
        <Input
          className="field"
          label={t('routes:description')}
          name="description"
          value={description}
          onChange={setDescription}
        />
        <Input
          className="field"
          label={t('routes:mocked-api')}
          name="mock"
          value={mock}
          onChange={setMock}
        />
      </ModalContent>
      <ModalActions>
        {
          isEdit
            ? (
              <Button
                className="add-action"
                onClick={handleEditRoute}
                disabled={disabled}
              >
                {t('routes:edit')}
              </Button>
            )
            : (
              <Button
                className="add-action"
                onClick={handleAddRoute}
                disabled={disabled}
              >
                {t('routes:add')}
              </Button>
            )
        }
      </ModalActions>
    </StyledModalAddRoute>
  );
}