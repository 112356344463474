import styled from "styled-components";
import { appTheme } from "../../styles/theme.styled";

export const StyledButton = styled.button`
  display: block;
  outline: none;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 1rem;
  text-align: center;
  text-decoration: none;
  padding: 0.75em;
  background-color: ${appTheme((theme) => theme.colors.primary)};
  color: ${appTheme((theme) => theme.colors.primaryAccent)};
  border: none;
  border-radius: 4px;
  box-shadow: ${appTheme((theme) => theme.shadows.primary)};
  font-weight: bold;
  position: relative;
  min-width: 150px;
  border: 2px solid ${appTheme((theme) => theme.colors.primary)};

  &[disabled] {
    background-color: ${appTheme((theme) => theme.colors.disabled)};
    border: 2px solid ${appTheme((theme) => theme.colors.disabled)};
    color: ${appTheme((theme) => theme.colors.text)};
    cursor: not-allowed;
  }

  &.inverted {
    border: 2px solid ${appTheme((theme) => theme.colors.primary)};
    background-color: ${appTheme((theme) => theme.colors.primaryAccent)};
    color: ${appTheme((theme) => theme.colors.primary)};
  }

  > * {
    margin: auto;
  }
`;

export const StyledRaisedButton = styled(StyledButton)`
  border-radius: 50%;
  height: 3rem;
  width: 3rem;
  padding: 0.5rem;

  position: fixed;
  bottom: 2rem;
  right: 2rem;

`;