import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Route, Switch, useHistory, useParams } from 'react-router';

import { getWorkspace } from '../../api/workspaces.api';
import { Button } from '../../components/button/button.component';
import { EmptyState } from '../../components/empty-state/empty-state.component';
import { Loader } from '../../components/loader/loader.component';
import { ModalName } from '../../components/modal/modal.registry';
import { RouteCard } from '../../components/route-card/route-card.component';
import { RouteDetails } from '../../components/route-details/route-details.component';
import { IWorkspaceDetails } from '../../models/workspace.model';
import { Routes } from '../../router/routes';
import { useModal } from '../../store/modal.context';
import { callApi } from '../../utils/make-api.util';
import { useReload } from '../../utils/reload.hook';
import { StyledWorkspaceDetails } from './workspace-details.styled';

export const WorkspaceDetails = () => {
  const { t } = useTranslation();
  const { workspaceId } = useParams<{ workspaceId: string }>();
  const history = useHistory();

  const [workspace, setWorkspace] = useState<IWorkspaceDetails>();
  const [isWorkspaceLoading, setIsWorkspaceLoading] = useState(true);

  const { show } = useModal();
  const [reload, setReload] = useReload();

  useEffect(() => {
    callApi(
      getWorkspace(workspaceId),
      (workspace) => {
        setWorkspace(workspace);
      },
    )
      .then(() => {
        setIsWorkspaceLoading(false)
      })
  }, [workspaceId, reload]);

  const handleAddRoute = useCallback(async () => {
    await show(ModalName.ADD_ROUTE, { workspace: workspaceId });
    setReload();
  }, [setReload, show, workspaceId]);

  return (
    <StyledWorkspaceDetails>
      <div className="routes-list">
        <div className="actions">
          <Button
            className="add-action"
            onClick={handleAddRoute}
            inverted
          >
            {t('routes:add')}
          </Button>
        </div>
        <div className="routes-list-container">
          <Loader loading={isWorkspaceLoading}>
            <>
              {
                workspace?.routes.map((route) => {
                  return (
                    <RouteCard
                      className="route-list-card"
                      key={route.id}
                      route={route}
                      onClick={() => history.push(generatePath(Routes.route, { workspaceId, routeId: route.id }))}
                    />
                  );
                })
              }
              {!workspace?.routes.length && (
                <EmptyState className="routes-empty-state">
                  {t('workspaces:empty-routes')}
                </EmptyState>
              )}
            </>
          </Loader>
        </div>
      </div>
      <div className="route-details">
        <Switch>
          <Route
            exact
            path={[Routes.workspace, Routes.route]}
            component={RouteDetails}
          />
        </Switch>
      </div>
    </StyledWorkspaceDetails>
  )
}