import { createContext, useContext, useState } from 'react';

const initialState = {
  isShown: false,
  data: {},
} as any;

const ToastContext = createContext(initialState);

export interface ModalProviderProps {
  children: JSX.Element;
}

export interface IToastContext {
  show: (message: string, time?: number) => void;
  close: () => void;
  isShown: boolean;
  message: string;
}

const initialTimeout = setTimeout(() => { }, 0);

export const ToastProvider = ({ children }: ModalProviderProps) => {
  const [showToast, setShowToast] = useState(false);
  const [message, setMessage] = useState('');

  const [timer, setTimer] = useState(initialTimeout);

  const value: IToastContext = {
    show: (message: string, time: number = 3000) => {

      let exitTimer = 0;

      if (showToast) {
        setShowToast(false);
        clearTimeout(timer);
        exitTimer = 300;
      }

      setTimeout(() => {
        setShowToast(true);
        setMessage(message)
        const timeout = setTimeout(() => {
          setShowToast(false);
          setTimer(initialTimeout);
        }, time);

        setTimer(timeout);
      }, exitTimer)
    },
    close: () => {
      if (timer) {
        clearTimeout(timer);
        setTimer(initialTimeout);
        setShowToast(false);
      }
    },
    isShown: showToast,
    message,
  };

  return (
    <ToastContext.Provider value={value}>
      {children}
    </ToastContext.Provider>
  )
}

export const useToast = (): IToastContext => {
  const context = useContext(ToastContext);
  if (context === undefined) {
    throw new Error('useModal must be used within a ModalProvider')
  }
  return context
}