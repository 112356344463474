import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import it from './assets/translations/it.json';
import en from './assets/translations/en.json';

const resources = {
  it: {
    translation: it,
  },
  en: {
    translation: en,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: "en",
    keySeparator: ':',
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    debug: true,
  });

export default i18n;