import styled from "styled-components";

export const StyledProfile = styled.div`
  width: 100%;
  max-width: 29rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  margin: auto;
  margin-bottom: 10rem;

  .user-avatar {
    margin-bottom: 2rem;
  }

  .user-info {
    width: 100%;
    margin-bottom: 2rem;

    .title {
      font-weight: bold;
      font-size: 2rem;
      margin-bottom: 2rem;
      text-align: center;
    }

    .user-name {
      display: flex;
      flex-direction: row;
      margin-bottom: 2rem;
      
      & > * {
        margin: 0 0.5rem;
        width: 50%;
      }
    }

    .user-password {
      padding: 0 0.5rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      .label {
        margin-right: 1rem;
      }
    }
  }

  .tokens-container {
    width: 100%;
    margin-bottom: 2rem;

    .tokens-table {
      margin-bottom: 1rem;
    }

    .token-actions {
      padding: 0 0.5rem;
      display: flex;
      justify-content: flex-end;
    }
  }

  .actions {
    display: flex;
    flex-direction: row;
    width: 100%;
    
    &>* {
      margin: 0 0.5rem;
      width: 50%;
    }

    .save {
      /* min-width: 15rem; */
    }
  }
`;