import cn from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { changePassword, postLogin } from '../../api/login.api';
import { ReactComponent as Logo } from '../../assets/images/mockingbird-logo.svg';
import { Button } from '../../components/button/button.component';
import { Input } from '../../components/input/input.component';
import { LoginPageLayout } from '../../components/login-page-layout/login-page-layout.component';
import { IUser } from '../../models/user.model';
import { Routes } from '../../router/routes';
import { useAuth } from '../../store/auth.context';
import { callApi, useQuery } from '../../utils/make-api.util';
import { StyledChangePassword } from './change-password.styled';

export const ChangePassword = () => {
  const { t } = useTranslation();
  const { verificationToken } = useQuery();
  const history = useHistory();
  const { updateUser } = useAuth();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [enableSend, setEnableSend] = useState(false);

  useEffect(() => {
    setEnableSend(password.length > 3 && password === confirmPassword);
  }, [password, confirmPassword]);

  const onSend = useCallback(() => {
    callApi(
      changePassword(password, verificationToken),
      async (user: IUser) => {
        try {
          const loginUser = await postLogin(user.email, password);
          updateUser(loginUser);
        } catch (error) { }
        history.push(Routes.workspaces);
      },
    );
  }, [history, password, updateUser, verificationToken])

  return (
    <StyledChangePassword className={cn('change-password-page')}>
      <LoginPageLayout>
        <div className="logo">
          <Logo />
        </div>
        <h1 className="page-title">{t('forgot-password:change-password')}</h1>
        <div className="change-password-form">
          <Input
            className="form-input"
            name="password"
            label={t('forgot-password:new-password')}
            type="password"
            value={password}
            onChange={setPassword}
          />
          <Input
            className="form-input"
            name="confirm-password"
            label={t('forgot-password:confirm-new-password')}
            type="password"
            value={confirmPassword}
            onChange={setConfirmPassword}
          />
        </div>
        <Button
          className="send-action"
          onClick={onSend}
          disabled={!enableSend}
        >
          {t('forgot-password:send')}
        </Button>
      </LoginPageLayout>
    </StyledChangePassword>
  );
}
