import cn from 'classnames';

import { StyledCopyLabel } from './copy-label.styled';
import { ReactComponent as CopyIcon } from '../../assets/icons/copy.svg';
import { useCallback } from 'react';

export interface CopyLabelProps {
  children: JSX.Element | JSX.Element[] | string;
  className?: string;
  value?: string;
  onCopy: (value?: string) => void;
}

export const CopyLabel = ({ className, children, onCopy, value }: CopyLabelProps) => {

  const onCopyHandler = useCallback(() => {
    onCopy?.(value);
  }, [onCopy, value]);

  return (
    <StyledCopyLabel className={cn('copy-label', className)} onClick={onCopyHandler}>
      <div className="copy-label-label">
        {children}
      </div>
      <div className="copy-icon" >
        <CopyIcon className="icon" />
      </div>
    </StyledCopyLabel>
  );
}