import { createContext, useContext, useState } from 'react';

import { ModalName } from '../components/modal/modal.registry';

const initialState = {
  isShown: false,
  data: null,
  modal: null,
} as any;

const ModalContext = createContext(initialState);

export interface ModalProviderProps {
  children: JSX.Element;
}

export interface IModalContext {
  hide: (data?: any) => void;
  show: (modalName: ModalName, data?: any) => Promise<any>;
  isShown: boolean;
  data: any;
  modal: ModalName;
}

export const ModalProvider = ({ children }: ModalProviderProps) => {
  const [showModal, setShowModal] = useState(false);
  const [currentModal, setCurrentModal] = useState<ModalName | null>(null);
  const [currentData, setCurrentData] = useState<any>(null);

  const [resolver, setResolver] = useState<any>(null);

  const value = {
    hide: (data: any) => {
      setShowModal(false);
      setTimeout(() => {
        setCurrentModal(null);
      }, 400);
      if (resolver) {
        resolver(data);
      }
    },
    show: (modalName: ModalName, data?: any): Promise<any> => {
      return new Promise((resolve) => {
        setResolver(() => resolve);

        setCurrentModal(modalName);
        setCurrentData(data);
        setShowModal(true);
      })

    },
    isShown: showModal,
    data: currentData,
    modal: currentModal,
  };

  return (
    <ModalContext.Provider value={value}>
      {children}
    </ModalContext.Provider>
  )
}

export const useModal = (): IModalContext => {

  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error('useModal must be used within a ModalProvider')
  }
  return context
}