import cn from 'classnames';

import { StyledModalHeader } from './modal-header.styled';
import { ReactComponent as CancelIcon } from '../../../../assets/icons/cancel.svg';
import { useModal } from '../../../../store/modal.context';
import { useCallback } from 'react';

export interface ModalHeaderProps {
  children: JSX.Element | string;
  close?: boolean;
  className?: string;
  onClose?: () => void;
}

export const ModalHeader = ({ className, children, onClose }: ModalHeaderProps) => {

  const { hide } = useModal();

  const onCloseHandler = useCallback(() => {
    if (onClose) {
      onClose();
    } else {
      hide()
    }
  }, [hide, onClose])

  return (
    <StyledModalHeader className={cn('modal-header', className)}>
      {children}
      <div className="close-icon" onClick={onCloseHandler}>
        <CancelIcon />
      </div>
    </StyledModalHeader>
  );
}