import styled from "styled-components";

export const StyledChangePassword = styled.div`
  height: 100%;

  .logo {
    margin-bottom: 2rem;
  }

  .page-title {
    text-align: center;

    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }

  .change-password-form {
    margin-bottom: 2rem;

    .form-input {
      margin-bottom: 1rem
    }
  }

  .send-action {
    width: 100%;
  }

`;
