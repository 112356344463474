import axios from 'axios';
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';

import { API_URL } from '../env';

const baseConfig = {
  baseURL: `${API_URL}/api`,
  // baseURL: '/api',
};

export const authHttp = axios.create(baseConfig)

export const http = axios.create(baseConfig)

interface DecodedJWT {
  exp: number;
  iat: number;
  sub: number;
}

http.interceptors.request.use((config) => {
  return {
    ...config,
    withCredentials: true,
  }
})

authHttp.interceptors.request.use(
  async (config) => {
    const token = Cookies.get('MockingbirdAuthentication');
    let refreshToken = true;
    if (token) {
      const decodedJWT: DecodedJWT = jwt_decode(token);
      const now = new Date();
      refreshToken = (now.getTime() - decodedJWT.exp * 1000) >= 60 * 1000;
    }

    if (refreshToken) {
      await axios.post('/api/auth/refresh')
    }

    return {
      ...config,
      withCredentials: true,
    };
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  },
);

authHttp.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    return Promise.reject(error);
  },
);

http.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    return Promise.reject(error);
  },
);
