import cn from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createToken } from '../../../../api/token.api';
import { useModal } from '../../../../store/modal.context';
import { callApi } from '../../../../utils/make-api.util';

import { Button } from '../../../button/button.component';
import { Input } from '../../../input/input.component';
import { ModalActions } from '../modal-actions/modal-actions.component';
import { ModalContent } from '../modal-content/modal-content.component';
import { ModalHeader } from '../modal-header/modal-header.component';
import { StyledModalAddToken } from './modal-add-token.styled';

export interface ModalChangePasswordProps {
  className?: string;
}

export const ModalAddToken = ({ className }: ModalChangePasswordProps) => {
  const { t } = useTranslation()
  const [label, setLabel] = useState('');
  const { hide } = useModal();

  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    setDisabled(!!label.length);
  }, [label.length]);

  const handleChangePassword = useCallback(() => {
    callApi(
      createToken(label),
      () => {
        hide()
      }
    )
  }, [hide, label]);

  return (
    <StyledModalAddToken className={cn('modal-add-token', className)}>
      <ModalHeader close={true}>
        {t('profile:token:add-token-title')}
      </ModalHeader>
      <ModalContent>
        <Input
          className="field"
          label={t('profile:token:label')}
          name="token-label"
          value={label}
          onChange={setLabel}
        />
      </ModalContent>
      <ModalActions>
        <Button
          className="add-token-action"
          onClick={handleChangePassword}
          disabled={!disabled}
        >
          {t('profile:token:add')}
        </Button>
      </ModalActions>
    </StyledModalAddToken>
  );
}