import Avatar from 'boring-avatars';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { logout } from '../../../../api/login.api';
import { Routes } from '../../../../router/routes';
import { useAuth } from '../../../../store/auth.context';
import { mainTheme } from '../../../../styles/theme.styled';
import { formatName } from '../../../../utils/format.util';
import { callApi } from '../../../../utils/make-api.util';
import { Dropdown, DropdownDirection } from '../../../dropdown/dropdown.component';
import { StyledUserDropdown } from './user-dropdown.styled';

declare var location: any;

export const UserDropdown = () => {

  const { t } = useTranslation();
  const auth = useAuth();
  const history = useHistory();

  const [open, setOpen] = useState(false);

  const goToProfile = () => {
    setOpen(false);
    history.push(Routes.profile);
  }

  const makeLogout = () => {
    callApi(
      logout(),
      () => {
        setOpen(false)
        location.reload();
      },
    )
  }

  return (
    <StyledUserDropdown>
      <Dropdown
        open={open}
        onOpen={setOpen}
        direction={DropdownDirection.left}
        handler={
          <Avatar
            size={40}
            name={String(auth.user.id)}
            variant="beam"
            colors={mainTheme.avatar}
          />
        }
      >
        <div className="user-dropdown-content">
          <div className="user-name">
            {formatName(auth.user)}
          </div>
          <div className="menu">
            <div className="menu-item" onClick={goToProfile}>
              {t('profile:profile')}
            </div>
          </div>
          <div className="logout" onClick={makeLogout}>
            {t('profile:logout')}
          </div>
        </div>
      </Dropdown>
    </StyledUserDropdown>
  )
}