import cn from 'classnames';

import { StyledModalContent } from './modal-content.styled';

export interface ModalContentProps {
  children: JSX.Element | JSX.Element[];
  className?: string;
}

export const ModalContent = ({ className, children }: ModalContentProps) => {
  return (
    <StyledModalContent className={cn('modal-content', className)}>
      { children}
    </StyledModalContent>
  );
}