import styled from 'styled-components';

export const StyledWorkspaces = styled.div`

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  
  .workspace {
    margin: 0 1rem 1rem 0;
  }
  
`;