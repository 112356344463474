export const validateJSON = (strJson: string): boolean => {
  try {
    JSON.parse(strJson);
    return true;
  } catch (error) {
    return false;
  }
}

export const formatJSON = (strJson: string): string => {
  if (validateJSON(strJson)) {
    return JSON.stringify(JSON.parse(strJson), null, 2);
  }
  return strJson;
}