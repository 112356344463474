import styled from "styled-components";
import { appTheme } from "../../styles/theme.styled";

export const StyledDropdown = styled.div`
  position: relative;
  cursor: pointer;
  user-select: none;

  .dropdown-content {
    opacity: 0;
    transition: opacity .3s ease, transform .3s ease;
    pointer-events: none;
    transform: translateY(0rem);
    background-color: ${appTheme(theme => theme.colors.white)};
    border-radius: 4px;
    position: absolute;
    top: 100%;
    box-shadow: ${appTheme(theme => theme.shadows.small)};
    z-index: 100;

    &.open {
      opacity: 1;
      transform: translateY(1rem);
      pointer-events: initial;
    }

    &.left {
      right: 0;
    }

    &.right {
      left: 0;
    }
  }
  
`;
