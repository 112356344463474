import styled from 'styled-components';

import { appTheme } from '../../styles/theme.styled';

export const StyledSignup = styled.div`
  height: 100%;
  
  .logo {
    margin-bottom: 2rem;
  }

  .page-title-container {
    position: relative;

    .go-back {
      height: 1rem;
      position: absolute;
      top: 50%;
      left: 1rem;
      transform: translateY(-50%);
      cursor: pointer;

      svg {
        height: 100%;
        fill: ${appTheme(theme => theme.colors.primary)}
      }
    }

    .page-title {
      text-align: center;
  
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: 1rem;
    }
  }


  .signup-container {
    .name-surname {
      display: flex;
      flex-direction: row;

      > * {
        width: 50%;
      }

      .name-input {
        margin-right: 0.5rem;
      }

      .surname-input {
        margin-left: 0.5rem;
      }
    }

    > * {
      margin-bottom: 1rem;
    }

    .confirm-password {
      margin-bottom: 2rem;
    }

    .signup-action {
      width: 100%;
    }
  }
`;