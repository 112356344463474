import styled from "styled-components";
import { appTheme } from "../../styles/theme.styled";

export const StyledRouteDetails = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
  position: relative;

  .route-title {
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    .title {
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: 0.5rem;
    }

  }

  .route-description {
    color: ${appTheme((theme) => theme.colors.secondary)};
    margin-bottom: 0.5rem;
  }

  .edit-icon {
    position: absolute;
    top: 0;
    right: 0;
    height: 1.5rem;
    cursor: pointer;
    /* margin-right: 0.5rem; */

    svg {
      height: 100%;
      fill: ${appTheme((theme) => theme.colors.primary)};
    }
  }

  .route-payload {
    flex-grow: 1;

    display: flex;
    flex-direction: column;

    .actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      margin-bottom: 1rem;

      > * {
        margin-left: 1rem;
      }
    }

    .editor {
      flex-grow: 1;
      height: 0;
      border: 1px solid ${appTheme((theme) => theme.colors.primary)};
      border-radius: 4px;
      overflow: hidden;
    }
  }
`;