import styled from "styled-components";
import { appTheme } from "../../../../styles/theme.styled";

export const StyledModalHeader = styled.h2`
  position: relative;
  color: ${appTheme((theme) => theme.colors.primary)};
  font-size: 2rem;
  font-weight: bold;
  padding-right: 3rem;
  margin-bottom: 2rem;

  .close-icon {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    padding: 0.5rem;
    cursor: pointer;

    svg {
      height: 1rem;
    }
  }
`;
