import { ChangeEvent, useCallback } from "react"
import { StyledInput } from "./input.styled"

export interface InputProps {
  name: string;
  value: string;
  onChange: (value: string, event: ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  placeholder?: string;
  type?: string;
  className?: string;
}

export const Input = ({ name, value, onChange, label, placeholder, type, className }: InputProps) => {

  const onChangeInput = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    onChange?.(event?.target?.value, event);
  }, [onChange])

  return (
    <StyledInput className={className}>
      {label && (
        <label htmlFor={`input-${name}`} className="input-label">{label}</label>
      )}
      <input
        type={type || 'text'}
        name={`input-${name}`}
        className="input-field"
        value={value}
        onChange={onChangeInput}
        placeholder={placeholder}
      />
    </StyledInput>
  )
}