import { IUser } from '../models/user.model';
import { authHttp, http } from './http';

export const postLogin = (email: string, password: string): Promise<IUser> => {
  return http.post('/auth/login', {
    email,
    password,
  });
}

export const getMe = (): Promise<IUser> => {
  return authHttp.get('/auth/me');
}

export const signup = (
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  recaptchaToken: string,
): Promise<void> => {
  return http.post(
    '/auth/signup',
    {
      email,
      firstName,
      lastName,
      password,
    },
    {
      headers: {
        recaptcha: recaptchaToken,
      },
    },
  );
}

export const checkEmail = (token: string): Promise<void> => {
  return http.post('/auth/check-email', { token });
}

export const verifyEmail = (token: string): Promise<void> => {
  return http.post('/auth/email-verification', { token });
}

export const forgotPassword = (email: string): Promise<void> => {
  return http.post('/forgot-password', { email });
}

export const changePassword = (password: string, token: string): Promise<IUser> => {
  return http.post('/auth/change-password', { password, token });
}

export const logout = (): Promise<void> => {
  return http.post('/auth/logout');
}